
const dataPortfolio = [

    {
        id: 1,
        title: 'Comprehensive Solutions',
        desc: `We provide everything from hardware and software to installation, ensuring a seamless interactive experience.`,
        show: 'true'
    },
    {
        id: 2,
        title: 'Custom Tailored Designs',
        desc: `Whether for business, entertainment, or education, our installations improve user engagement, leaving a lasting impression.`
    },
    {
        id: 3,
        title: 'Enhanced Engagement',
        desc: `Whether for business, entertainment, or education, our installations improve user engagement, leaving a lasting impression.`
    },
]

export default dataPortfolio;