import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import SolutionPortfolioTwo from '../../components/portfolio/SolutionPortfolioTwo';
import dataIntreactiveInstallations from '../../assets/data/data-intreactive-installations';
import dataVrDevice from '../../assets/data/data-vr-devices';
import dataInteractiveWall from '../../assets/data/data-interactive-wall';
import dataTouchlessGame from '../../assets/data/data-touchless-games';
import dataImmersiveRoom from '../../assets/data/data-immersive-room';
import dataFaq from '../../assets/data/data-interactive-installations-faq';
import faqImg from '../../assets/Services/interactive-installations/interactiveFaq.jpg';
import SolutionFaqsTwo from '../../components/faqs/SolutionFaqsTwo';
import contactImg from '../../assets/Services/have-a-project2.png';
const tengibleTable ="https://www.youtube.com/embed/1GBpVaYEwR8?controls=0&modestbranding=1&showinfo=0&rel=0";
const vrDevicesVidoe ="https://www.youtube.com/embed/AF-pOgPd7iY?controls=0&modestbranding=1&showinfo=0&rel=0";
const touchlessGameVidoe ="https://www.youtube.com/embed/R22fs0bcmEA?controls=0&modestbranding=1&showinfo=0&rel=0";
const immersiveRoomVidoe ="https://www.youtube.com/embed/URE19Eb1EeY?controls=0&modestbranding=1&showinfo=0&rel=0";
const interactiveWallVidoe ="https://www.youtube.com/embed/V8tr0LFwVfA?controls=0&modestbranding=1&showinfo=0&rel=0";


function InteractiveInstallations(props) {
    const bannerData = {
        heading: "Transform Your Space with Interactive 'Installations",
        desc: `Turn any space into a captivating experience with our cutting-edge immersive tech. From <b>retail and offices to events and public spaces</b>, we create unforgettable environments that engage and inspire.`,
        desc1: `With <b>top-tier hardware</b>, seamless setups, and <b>custom software</b>, we deliver solutions tailored to your vision.`,
        button: false,
        btnTitle: "Talk To Our XR Experts",
        link: '/contact',
        images:[],
    };
    const installationsHeaderData = {
        heading: 'Interactive Tangible Tables',
        desc:"Engage users with hands-on interaction through digital content on touch-sensitive tables.",
        video:tengibleTable,
    };
    const vRDevicesHeaderData = {
        heading: 'VR Devices',
        desc:"Immerse users in virtual worlds for interactive tours, training, and entertainment with advanced VR devices.",
        video:vrDevicesVidoe,
    };
    const  interactiveWallHeaderData= {
        heading: 'Interactive Wall',
        desc:" Create dynamic, touch-responsive walls that transform your space with engaging visuals.",
        video: interactiveWallVidoe,
    };
    const touchlessGameHeaderData = {
        heading: 'Touchless Games',
        desc:"Captivate your audience with touchless motion games for better engagement. Play games with only your movements, offering a hygienic and fun interactive experience.",
        video:touchlessGameVidoe,
    };
    const immersiveRoomHeaderData = {
        heading: 'Immersive Room',
        desc:"Create fully immersive environments with projectors, sound, and interactive displays for training or storytelling.",
        video:immersiveRoomVidoe,
    };
    const faqHeaderData = {
        img: faqImg,
        heading: 'Why Choose AVR Labs?',
       };
    const contactData = {
        img: contactImg,
        heading5: 'Ready to transform your space with futuristic interactive technology?',
        desc: 'Contact us today to explore how our technology can transform your space and engage your audience in new, exciting ways.',
        btn: true,
        target: 'blank',
        btnTitle: 'Contact us today!',
        link: 'https://calendly.com/raed-avr/',
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <div className='main-sec-no-padd'>
                <SolutionPortfolio headerData={installationsHeaderData} data={dataIntreactiveInstallations} step='false' numbering='false' />
            </div>
            <div className='main-sec-no-padd'>
                <SolutionPortfolioTwo headerData={vRDevicesHeaderData} data={dataVrDevice} step='false' numbering='false' />
            </div>
            <div className='main-sec-no-padd'>
                <SolutionPortfolio headerData={interactiveWallHeaderData} data={dataInteractiveWall} step='false' numbering='false' />
            </div>
            <div className='main-sec-no-padd'>
                <SolutionPortfolioTwo headerData={touchlessGameHeaderData} data={dataTouchlessGame} step='false' numbering='false' />
            </div>
            <div className='main-sec-no-padd'>
                <SolutionPortfolio headerData={immersiveRoomHeaderData} data={dataImmersiveRoom} step='false' numbering='false' />
            </div>
            <SolutionFaqsTwo dataBlock={faqHeaderData} data={dataFaq}  />        
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default InteractiveInstallations;