const data = [

    {
        id: '',
        title: 'Technology',
        desc: `Projectors, touch sensors, and interactive software like Touch Designer. Infrared cameras or capacitive touch may be integrated for enhanced functionality.`
    },
    {
        id: '',
        title: 'Applications',
        desc: `Ideal for collaborative spaces, retail displays, and public installations. Create dynamic, responsive walls that interact with touch, providing a visually engaging experience.`
    },
]

export default data;