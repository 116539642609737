import arGeoSpatialImg from "../images/item/item-01.png";
import holographicDisplaysImg from "../industries/Marketing/Holographic Displays.jpg";
import metaverseServicesImg from "../industries/Marketing/MetaverseServices.jpg";

const vimeoVideoUrls = {
  ai: "https://player.vimeo.com/video/936100460?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
  packaging: "https://player.vimeo.com/video/936132157?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",   
  viBeings: "https://player.vimeo.com/video/936100460?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",   
  arGeoSpatial: "https://player.vimeo.com/video/936132185?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",   
   
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: '',
    title: "AR Geo Spatial",
    desc: `<p>Enhance marketing strategies with AR Geo-Spatial, providing location-based augmented reality experiences for targeted campaigns, delivering dynamic and location-specific content to engage audiences.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['arGeoSpatial'],
    link: "/services/extended-reality",
  },
  {
    id: 2,
    img: '',
    iframe: '',
    title: "AR for Packaging and Print-outs",
    desc: `<p>Elevate product marketing with AR for Packaging and Print-outs. Merge physical materials with digital interactivity, offering customers immersive and informative experiences, enhancing brand engagement.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['packaging'],
    link: "/services/extended-reality",
  },
  {
    id: 3,
    img: '',
    iframe:'https://portal.arviewz.com/products/620/modelviewer?arModel=true',
    title: "AR for Products",
    desc: `<p>Revolutionize product promotion with AR for Products. Allow customers to virtually interact with items, visualizing features and benefits, creating an engaging and personalized marketing experience.<p>`,
    button: true,
    slug: "S01",
    btnTitle: "Learn more",
    video:'',
    link: "/solutions/ar-visualizer",
  },
  {
    id: 4,
    img: '',
    iframe:'https://virtualdesignshub.com/stagingnew/viewer/index.php?code=c81e728d9d4c2f636f067f89cc14862c',
    title: "Virtual Websites",
    desc: `<p>Transform online presence with Virtual Websites. Offer immersive and interactive online experiences, engaging visitors with dynamic content and personalized interactions, elevating the overall digital marketing strategy.<p>`,
    button: true,
    slug: "A05",
    btnTitle: "Learn more",
    video:'',
    link: "/services/virtual-web-development",
  },
  // {
  //   id: 44,
  //   img: '',
  //   iframe:'',
  //   title: "Creative Concepts",
  //   desc: `<p>Transform online presence wInnovate marketing campaigns with Creative Concepts. From interactive installations to dynamic presentations, these innovative ideas captivate audiences, conveying messages and enhancing the overall impact of marketing initiatives.<p>`,
  //   button: true,
  //   slug: "A05",
  //   btnTitle: "Learn more",
  //   video:'',
  //   link: "/services/virtual-web-development",
  // },
  {
    id: 5,
    img: '',
    title: "Virtual Being",
    desc: `<p>Boost marketing efficiency with AI. Leverage artificial intelligence for data analysis, personalized recommendations, and targeted campaigns, enhancing customer engagement and optimizing marketing strategies.<p>`,
    button: true,
    slug: "A03",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['viBeings'] ,
    link: "/services/virtual-web-development",
  },
  {
    id: 6,
    img: '',
    title: "Interactive Installations",
    desc: `<p>Captivate audiences with Interactive Installations. Engage customers in physical spaces through interactive displays, creating memorable and shareable experiences that amplify brand awareness and engagement.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    iframe:'https://portal.arviewz.com/products/346/modelviewer?arModel=true',
    video:"",
    link: "",
  },
  {
    id: 7,
    img: metaverseServicesImg,
    title: "Metaverse Services",
    desc: `<p>Pioneer marketing in the metaverse. Explore new dimensions with Metaverse Services, establishing a virtual presence for brands, offering immersive experiences, and connecting with audiences in novel and meaningful ways.<p>`,
    button: true,
    topHeadingDesign: true,
    slug: "A03",
    btnTitle: "Learn more",
    video:'',
    link: "/services/metaverse-services",
  },
  {
    id: 7,
    img: holographicDisplaysImg,
    title: "Holographic Displays",
    desc: `<p>Capture attention with Holographic Displays. Deliver visually stunning marketing presentations, product showcases, and brand stories, creating a futuristic and captivating visual experience for customers.<p>`,
    button: true,
    slug: "S07",
    btnTitle: "Learn more",
    topHeadingDesign: true,
    headingRight: true,
    video:'',
    link: "/solutions/holographic-displays",
  },
];

export default dataItem;
