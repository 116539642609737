import img5 from "../images/item/item-05.png";
import img6 from "../images/item/item-06.png";
import img7 from "../images/item/item-07.png";
import img8 from "../images/item/item-08.png";
import POS1  from "../projects/thumbnails/P01--ar-navigation.jpg";
import POS3  from "../projects/thumbnails/P03 - Virtual Art Gallery - Nagla ali.png";
import POS4  from "../projects/thumbnails/P04 - AR Dubai Metaverse -  Robots in AR (1).png";
import POS5  from "../projects/thumbnails/P05 - Entertainment Virtual Reality Liwa.png";
import POS9  from "../projects/thumbnails/P09 - AR for Art - Luxverse.png";
import POS10  from "../projects/thumbnails/P10 - Dubai Metaverse Assembly.png";
import POS11  from "../projects/thumbnails/P11 - Rattan House virtual store.png";
import POS12  from "../projects/thumbnails/P12 - Kit & Kaboodle Virtual Tour.png";
import POS13  from "../projects/thumbnails/P13---Digital-Twin---Dubai-Future-foundation.jpg";
import POS14  from "../projects/thumbnails/P14 - Al Zohour Villa.png";
import POS15  from "../projects/thumbnails/P15-Al-Sweidi-Villa-AR.jpg";
import POS16  from "../projects/thumbnails/P16 - Joelle Clinic.jpg";
import POS18  from "../projects/thumbnails/P18 - Zayed Complex Interactive Tour - Ministry of Education.png";
import POS19  from "../projects/thumbnails/P19 - Bateel.png";
import POS20  from "../projects/thumbnails/P20 - NFT-El-Rabe3.png";
import POS21  from "../projects/thumbnails/P21 - Private House Interactive Design.png";
import POS22  from "../projects/thumbnails/world future energy.png";
import POS23  from "../projects/thumbnails/TechCraft.png";
import POS24  from "../projects/thumbnails/TransformingRealEstateDevelopments.png";
import POS25  from "../projects/thumbnails/P24-Gmg-min-1568x882.jpg";
import POS26  from "../projects/thumbnails/P25-AI-powered-holographic-new.png";
import POS27  from "../projects/thumbnails/P27-AR-3D-Visualization-for-Event Rentals.png";
import POS28  from "../projects/thumbnails/P28-Grandseal Solutions.png";
import POS29  from "../projects/thumbnails/P29-Immersive Room Solutions.png";

const vimeoVideoUrls = {
  P03: "https://player.vimeo.com/video/929219923?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P04: "https://player.vimeo.com/video/929220672?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P05: "https://player.vimeo.com/video/929220592?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P09: "https://player.vimeo.com/video/929220642?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P10: "https://player.vimeo.com/video/929220519?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P11: "https://www.youtube.com/embed/TB8ydaFB9iY?controls=0&modestbranding=1&showinfo=0&rel=0",
  P12: "https://player.vimeo.com/video/929220437?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  P14: "https://player.vimeo.com/video/929220400?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
  P15: "https://player.vimeo.com/video/1030892592?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P16: "https://player.vimeo.com/video/929220150?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
  P18: "https://player.vimeo.com/video/929220199?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
  P19: "https://player.vimeo.com/video/929220281?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
  P21: "https://player.vimeo.com/video/929220340?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&byline=0&amp;portrait=0",
  P22: "https://player.vimeo.com/video/968837080?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P23: "https://player.vimeo.com/video/968837865?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P24: "https://player.vimeo.com/video/986925148?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P25: "https://player.vimeo.com/video/1005739147?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P26: "https://player.vimeo.com/video/1013880567?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P27: "https://player.vimeo.com/video/1028319548?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  P28: "https://www.youtube.com/embed/tr1gUdIuf-A?controls=0&modestbranding=1&showinfo=0&rel=0",
  P29: "https://www.youtube.com/embed/zXm5JR3aerI?controls=0&modestbranding=1&showinfo=0&rel=0"
};

const dataItem = [
  // {
  //   id: 1,
  //   img: POS1,
  //   title: "World Future Energy Summit - AR Navigation",
  //   vedio: "",
  //   slug: "P01",
  //   tags:['Augmented Reality'],
  //   data: [
  //     {
  //       title: "Inprogress",
  //       desc: " no text yet",
  //     },
  //   ],
  // },
  {
    id: 2,
    img: POS3,
    title: "Virtual Art Gallery - Nagla ali",
    video: vimeoVideoUrls["P03"],
    slug: "P03",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: "Overview",
        desc: "Step into the future of art with AVR Labs where we transformed conventional art gallery experience through cutting-edge technology for our esteemed client Nagla Ali.",
      },
      {
        title: "Challenges",
        desc: "Bringing artwork to life in a virtual space can be tricky. How do we create an immersive experience that truly captures the essence of each piece?",
        class: "right",
      },
      {
        title: "Our Solutions",
        desc: "With meticulous planning and innovative thinking, we crafted an Interactive Virtual Tour for Nagla Ali's Virtual Art Gallery. Leveraging augmented reality (AR) and virtual reality (VR), we unlocked new dimensions for art appreciation. Our team collaborated closely with Nagla Ali to understand her artistic essence, ensuring every element resonated with her vision.",
      },
      {
        title: "Highlights",
        desc: "<ul><li>- Explore stunning artwork from the comfort of your own home.</li><li>- Interact with each piece like never before, thanks to immersive AR features.</li><li>- Experience the magic of VR, stepping into a virtual world where creativity knows no bounds.</li></ul>",
        class: "right",
      },
      {
        title: "Technology Used",
        desc: "<ul><li>- Augmented Reality (AR).</li><li>- Virtual Reality (VR).</li></ul>",
      },
      {
        title: "CTA",
        desc: "Ready to create your own virtual space? Contact us for Expert Advice!",
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 3,
    img: POS4,
    title: "AR Dubai Metaverse -  Robots in AR",
    video: vimeoVideoUrls["P04"],
    slug: "P04",
    tags:['Augmented Reality'],
    data: [
      {
        title: "Overview",
        desc: "The Dubai Metaverse Assembly stands as a groundbreaking event that pushes the boundaries of human-machine interaction, blending reality and virtuality to offer attendees an immersive experience like never before. At its core, the assembly introduces Augmented Reality (AR) robots, setting the stage for an innovative convergence of technology and human engagement.",
      },
      {
        title: "Problem",
        desc: "Traditional events often lack interactivity and fail to engage attendees on a meaningful level. Furthermore, with the rise of virtual experiences, there is a growing need to bridge the gap between physical and digital realms to create truly immersive environments.",
        class: "right",
      },
      {
        title: "Our Solutions",
        desc: "To address these challenges, we conceptualized the Dubai Metaverse Assembly, an event that seamlessly integrates AR robots into the attendee experience. These meticulously designed and programmed robots serve as companions and guides, navigating participants through a carefully crafted metaverse that blurs the lines between reality and virtuality.",
        class: "",
      },
      {
        title: "Highlights",
        desc: `Immersive Experience: The Dubai Metaverse Assembly offers attendees a symphony of sights and sounds, where the physical and digital worlds intertwine seamlessly.`,
        class: "right",
      },
      {
        title: "Augmented Reality Robots",
        desc: `These AR robots, accessible through attendees' own devices, come to life to provide information, lead activities, and spark conversations, adding depth and dimension to the event.
            Technological Innovation: Leveraging AR technology, the assembly redefines the traditional event landscape, offering unparalleled interactivity and immersion.`,
        class: "",
      },
      {
        title: "Meaningful Connections",
        desc: `By fostering interactions between attendees and AR robots, the event creates opportunities for meaningful connections and experiences.`,
        class: "right",
      },
      {
        title: "Technology Used",
        desc: `Augmented Reality (AR) Technology: AR technology integration enabling  digital elements into the physical environment.`,
        class: "",
      },
      {
        title: "Design and Programming",
        desc: `The AR robots are meticulously designed and programmed to provide a diverse range of interactions and experiences for attendees.`,
        class: "right",
      },
      {
        title: "CTA",
        desc: `Transform your events with AR experience. Contact Our Experts Today!`,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 4,
    img: POS5,
    title: "Entertainment Virtual Reality Liwa",
    slug: "P05",
    video: vimeoVideoUrls["P05"],
    tags:['Virtual Reality'],
    data: [
      {
        title: "Overview",
        desc: "An immersive journey of the Liwa Ajman Dates & Honey Festival, where AVR Labs created a transformative experience for festival attendees. In this captivating project, we harnessed the power of virtual reality (VR) to transport visitors into a picturesque palm farm, creating an educational and cultural exploration like never before.",
      },
      {
        title: "Key Features",
        desc: "Virtual Farm Exploration, Interactive Beehive Learning, Cultural Education Enhancement",
        class: "right",
      },
      {
        title: "Objectives",
        desc: "Enhance festival-goers' understanding of the cultural and agricultural significance of dates and honey in the UAE. ",
      },
      {
        title: "Challenges",
        desc: "Effectively engaging festival attendees and providing an innovative, educational experience to deepen their cultural connections.",
        class: "right",
      },
      {
        title: `Solution`,
        desc: "AVR Labs devised a unique solution.",
      },
      {
        title: "Virtual Farm Exploration",
        desc: "<p>Created a digital palm farm, allowing festival-goers to explore palm trees up close.</p><p>Interactive Beehive Learning: Introduced a friendly digital bee guiding visitors to a virtual beehive, enhancing education about beekeeping and date types.</p><p>Cultural Education Enhancement: The immersive VR experience resulted in a 40% increase in cultural appreciation among festival attendees.</p>",
        class: "right",
      },
      {
        title: "Results",
        desc: "The Liwa Festival project showcases AVR Labs' ability to leverage VR for cultural education, creating an interactive journey that leaves a lasting impact.",
      },
      {
        title: "CTA",
        desc: "Elevate your events with AVR Labs' immersive experiences. Contact us for transformative cultural engagements.",
        class: "right",
        contactButton: false
      },
    ],
  },
  // // {
  // //   id: 5,
  // //   img: img5,
  // //   title: "Cop 28 - ESE MAP and screens",
  // //   slug: "P06",
  // //   tags:['Event Solutions'],
  // //   data: [
  // //     {
  // //       title: "Overview",
  // //       desc: `It was a privilage to collaborate with Emirates School Establishment (ESE) to enhance their sustainability initiatives at the Cop 28 event. Our mission was to provide an innovative solution that not only showcased ESE's commitment to sustainability but also made it accessible and interactive for all attendees.`,
  // //     },
  // //     {
  // //       title: `Project Highlights`,
  // //       desc: `Our customized map and screens served as invaluable tools for attendees, offering real-time insights into ESE's sustainability initiatives. From renewable energy projects to waste reduction efforts, attendees could easily navigate and engage with ESE's commitment to sustainability. Moreover, our collaboration contributed to the overall success of COP28, reinforcing the importance of sustainability in education.`,
  // //       class: "right",
  // //     },
  // //     {
  // //       title: `Technology Used`,
  // //       desc: `<ul><li>- Interactive Maps</li><li>- Digital Screens</li></ul>`,
  // //       class: "",
  // //     },
  // //     {
  // //       title: `CTA`,
  // //       desc: `Contact us to explore how AVR Labs can elevate your organization's sustainability initiatives today!`,
  // //       class: "right",
  // //     },
  // //   ],
  // // },
  // {
  //   id: 6,
  //   img: img6,
  //   title: "Virbe - ESE - Orange",
  //   slug: "P07",
  //   video: "",
  //   tags:['Artificial Intelligence'],
  //   data: [
  //     {
  //       title: "Transforming Customer's Engagement: AI Virtual Assistant",
  //       desc: `Partnering with Orange, a leading technical firm, AVR Labs undertook an innovative project aimed at revolutionizing customer engagement during events. The objective was to introduce an innovative AI Virtual Assistant deployed on kiosks, enhancing visitor interaction and amplifying brand awareness.`,
  //     },
  //     {
  //       title: "Challenge",
  //       desc: `Orange faced the challenge of engaging event attendees in a meaningful way while simultaneously promoting brand recognition. Traditional methods fell short in capturing audience attention amidst the busy event, requiring an innovative method for interaction and engagement.`,
  //       class: "right",
  //     },
  //     {
  //       title: `Solution`,
  //       desc: `AVR Labs crafted an innovative solution using Artificial Intelligence (AI) to create a dynamic Virtual Assistant. With customized avatar for Orange, this AI-powered assistant engages visitors, provides information, and answers questions effortlessly. By merging advanced tech with interactive kiosks, we aimed to offer attendees an immersive experience.`,
  //     },
  //     {
  //       title: `Project Highlight`,
  //       desc: `The deployment of the AI Virtual Assistant marked a turning point for the brand. With captivating kiosks featuring the AI assistant, attendees were drawn to interactive hubs that showcased their brand identity and offerings.`,
  //       class: "right",
  //     },
  //     {
  //       title: `Technology Used`,
  //       desc: `<ul><li>- Artificial Intelligence</li><li>- Interactive Kiosks</li></ul>`,
  //     },
  //     {
  //       title: `CTA`,
  //       desc: `Integrate an AI-Powered Virtual Assistant into your website, application, or kiosks.Contact us now to acquire your personalized AI Virtual Assistant.`,
  //       class: "right",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   img: img7,
  //   title: "Game Kinect - GMG",
  //   slug: "P08",
  //   video: "",
  //   tags:['Interactive Games'],
  //   data: [
  //     {
  //       title: `overview`,
  //       desc: `AVR Labs partnered with GMG, a conglomerate spanning healthcare, cosmetics, and pharmacy industries, to create an engaging game for an event. The goal was to captivate the audience by offering a unique gaming experience using kinetic devices.`,
  //     },
  //     {
  //       title: `Challenges`,
  //       desc: `GMG needed to engage attendees from diverse industries while maintaining brand relevance. They sought an interactive solution that would entertain participants while staying true to each brand's identity.`,
  //       class: "right",
  //     },
  //     {
  //       title: `Solution`,
  //       desc: `AVR Labs developed a customized interactive game using Kinect technology. This allowed attendees to actively participate, play, and win their products. By aligning the game with brand identities, we ensured maximum engagement and relevance.`,
  //     },
  //     {
  //       title: `Project Highlights`,
  //       desc: `The interactive game became the focus point of the event, attracting participants from various industries. Through seamless integration with kinetic devices, attendees enjoyed an immersive gaming experience, fostering memorable interactions and reinforcing brand engagement.`,
  //       class: "right",
  //     },
  //     {
  //       title: `Technology Used`,
  //       desc: `<ul><li>- Kinect Technology</li><li>- Interactive Gaming Platform</li></ul>`,
  //     },
  //     {
  //       title: `CTA`,
  //       desc: `Elevate your event with interactive kinectic games. Contact us to create an innovative gaming experience for your brand.`,
  //       class: "right",
  //     },
  //   ],
  // },
  {
    id: 8,
    img: POS9,
    title: "AR for Art - Luxverse",
    video: vimeoVideoUrls["P09"],
    slug: "P09",
    tags:['Augmented Reality'],
    data: [
      {
        title: `Overview`,
        desc: `A groundbreaking project to transform the way artwork is showcased. With the help of Augmented Reality (AR), the goal was to create an immersive experience for users to preview artwork simply by scanning a code.`,
      },
      {
        title: `Challenges`,
        desc: `Artists often face limitations in sharing their work due to physical constraints. Our challenge was to break these barriers, enabling artists worldwide to showcase their creations in a whole new way. The aim was to empower artists to create AR experiences for their artwork, allowing users to preview it in any location using their mobile devices.`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `AVR Labs created an AR experience at Luxeverse using AR platform (AR Viewz), empowering artists to transcend physical boundaries. With our AR Art Experience, artists could offer their audience the chance to view their artwork in real spaces through augmented reality. This seamless integration of technology and art provided users with an engaging and interactive way to experience art like never before.`,
      },
      {
        title: `Project Highlight`,
        desc: `The AR Art Experience revolutionized art viewing, offering a boundaryless platform for artists to connect with their audience. By enabling users to preview artwork in real spaces via AR, the project created a captivating and immersive experience. The seamless fusion of technology and art opened up new avenues for artistic expression and audience engagement.`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Augmented Reality (AR) technology</li></ul>`,
      },
      {
        title: `CTA`,
        desc: `Contact us and let us help you create an AR experience of your product.`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 9,
    img: POS10,
    title: "Dubai Metaverse Assembly - Dubai future foundation",
    video: vimeoVideoUrls["P10"],
    slug: "P10",
    tags:['Metaverse'],
    data: [
      {
        title: `Overview`,
        desc: `Dubai Future Foundation project, a pioneering endeavor by AVR Labs that seamlessly merged the digital and physical worlds, creating a Metaverse that breaks barriers./n AVR Labs spearheaded the creation of a groundbreaking Metaverse for the Dubai Future Foundation project, where the virtual realm coalesced with physical events to offer an unparalleled experience.`,
      },
      {
        title: `Key Features`,
        desc: `<ul><li>- Metaverse-Physical Event Synchronization: Ensured synchronization between physical events and the Metaverse, providing a cohesive experience for participants.</li><li>- QR Code Interaction: Enabled participants to engage with the Metaverse by scanning QR codes, offering virtual encounters with AR robots and immersive experiences.</li></ul>`,
        class: "right",
      },
      {
        title: `Challenges`,
        desc: `Synchronizing the Metaverse with physical events posed a significant challenge, necessitating a cohesive approach to bridge the gap between virtual and real-world experiences.`,
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs devised an innovative solution:</p>
                <ul><li>- Event Synchronization ensured real-time reflection of on-stage activities, enhancing participant engagement.</li>
                    <li>- QR Code Interaction enabled virtual encounters with AR robots, offering immersive experiences.</li>
                </ul>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Metaverse</li><li>- Virtual Reality</li><li>- Augmented Reality</li></ul><p>The Dubai Future Foundation project showcases AVR Labs' prowess in harmonizing digital and physical realms, setting new standards for immersive event experiences.</p>`,
      },
      {
        title: `CTA`,
        desc: `Experience the immersive future with AVR Labs. Contact us to redefine your event engagement.`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 10,
    img: POS11,
    title: "Rattan House virtual store",
    video: vimeoVideoUrls["P11"],
    tags:['Virtual Reality'],
    slug: "P11",
    data: [
      {
        title: `Overview`,
        desc: `In a groundbreaking partnership with Rattan House, AVR Labs embarked on a mission to redefine the online shopping experience. The result was the birth of the world's premier web-based virtual store, a revolutionary concept enriched with the power of Augmented Reality (AR) and Virtual Reality (VR). This project was nothing short of an immersive e-commerce odyssey, fusing technology and innovation with the realm of furniture and decor.`,
      },
      {
        title: `Key Features`,
        desc: ` Augmented Reality Virtual Store, Real-time Product Interaction, Dynamic E-Commerce Odyssey
        Objective: Bridge the gap between traditional in-store shopping and the digital frontier, providing a visually stimulating and captivating online experience.`,
        class: "right",
      },
      {
        title: `Challenges`,
        desc: `Rattan House, a respected name in furniture retail, sought to bridge the gap between traditional in-store shopping and the digital frontier. The challenge was clear: transform the way customers perceive and interact with their products online. The objective was to transport online shoppers into a visually stimulating and captivating realm, just like the in-store experience.`,
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs spearheaded a transformative solution:</p>
                <p>Augmented Reality Virtual Store: Introduced a fully immersive 3D virtual store, allowing visitors to interact with digital representations of furniture and decor.</p>
                <p>Real-time Product Interaction: Enabled visitors to virtually position furniture within their living spaces, fostering a showroom experience in homes.</p>
                <p>Dynamic E-Commerce Odyssey: Resulted in a remarkable 50% surge in sales, showcasing the success of the immersive e-commerce venture.</p>`,
        class: "right",
      },
      {
        title: `Results`,
        desc: `<p>The Rattan House project exemplifies AVR Labs' commitment to reshaping the digital retail landscape, delivering transformative and engaging online shopping experiences.</p>`,
      },
      {
        title: `CTA`,
        desc: `Explore the future of digital retail with AVR Labs. Contact us for personalized demonstrations of our transformative technology.`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 11,
    img: POS12,
    title: "Kit & Kaboodle Virtual Tour",
    video: vimeoVideoUrls["P12"],
    tags:['Interactive Virtual Tour'],
    slug: "P12",
    data: [
      {
        title: `Overview`,
        desc: `Kit & Kaboodle, a prestigious Interior Design and luxury furniture showroom located in Dubai, UAE. In this case study, we uncover the extraordinary transformation of a traditional brick-and-mortar showroom into an interactive virtual space. AVR Labs, in close collaboration with Kit & Kaboodle, undertook a pioneering project that reshaped the presentation of interior design offerings.`,
      },
      {
        title: `Key Features`,
        desc: `<p>Interactive Virtual Showroom with Real-time Product Updates.</p>`,
        class: "right",
      },
      {
        title: `Objective`,
        desc: `To empower Kit & Kaboodle with a virtual space for seamless real-time product updates, revolutionizing their product presentation process.`,
      },
      {
        title: `Challenges`,
        desc: `<p>Kit & Kaboodle faced a unique challenge: the need for an innovative solution to showcase their diverse product range dynamically. The objective was to eliminate the dependence on external support for product updates and create an engaging virtual showroom even if they update their products on a daily basis.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs devised a comprehensive solution that empowered Kit & Kaboodle:</p>
                <p>Interactive Virtual Showroom: The physical showroom seamlessly transitioned into a captivating virtual space. This digital environment offered clients an immersive experience, allowing them to explore interior design options as if they were physically present.</p>
                <p>Real-time Product Updates: A user-friendly system was integrated, enabling the Kit & Kaboodle's team to make real-time updates to their product catalog independently. This eliminated the need for external support and streamlined product management.</p>
                <p>Subscription Model: To ensure cost-effectiveness, a subscription model was introduced. Kit & Kaboodle made a one-time payment for the virtual tour setup and continued with the subscription, granting them the autonomy to continuously update their product catalog, thus reducing ongoing costs.</p>`,
        class: "",
      },
      {
        title: `Results`,
        desc: `<p>The implementation of the Kit & Kaboodle project yielded outstanding outcomes:</p>
                <p>Effortless Product Management: Kit & Kaboodle's team can now update their product offerings on the go, eliminating the need for external support.</p>
                <p>Dynamic Virtual Showroom: The interactive virtual space captivated clients, offering a dynamic platform for exploring interior design options.</p>
                <p>Cost-Effective Solution: The subscription model allowed Kit & Kaboodle to maintain their virtual showroom efficiently, leading to reduced ongoing expenses.</p>
                <p>The Kit & Kaboodle project symbolizes the success of the partnership between Kit & Kaboodle and AVR Labs. By embracing innovation and technology, Kit & Kaboodle now boasts a dynamic, cost-effective, and self-sufficient virtual showroom. This project is a testament to the boundless opportunities created at the intersection of technology and interior design.</p>`,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `Ready to embark on your transformational journey? Let's connect and discuss your vision!`,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 12,
    img: POS13,
    title: "Digital Twin - Dubai Future foundation",
    video: "",
    slug: "P13",
    tags:['Virtual Reality'],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs partnered with the Dubai Future Foundation to pioneer a groundbreaking project in the realm of Virtual Reality (VR) - the Digital Twin Integration.`,
      },
      {
        title: `Challenges`,
        desc: `<p>The challenge was to seamlessly create a 3D Digital Twin of the event space, ensuring a cohesive and dynamic interaction with the VR technology. The task required overcoming technical hurdles and synchronizing the virtual and physical elements to provide a seamless experience for attendees.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `Digital Twin Integration: By creating a 3D digital replica of the event space, AVR Labs established an immersive environment for exploration within the Metaverse.`,
      },
      {
        title: `Project Highlights`,
        desc: `<p>The highlight of the project was the successful integration of the  Digital Twin, which transformed the event into a cutting-edge immersive experience. Attendees were able to explore the virtual environment, interact with digital elements, and gain valuable insights into future technologies.</p>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Virtual Reality (VR)</li></ul>`,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Contact us today to explore how Digital Twin Integration can elevate your next event experience.</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 13,
    img: POS14,
    title: "Al Zohour Villa",
    video: vimeoVideoUrls["P14"],
    slug: "P14",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Overview`,
        desc: `Zohour Investment Project, a story of innovation and the dynamic partnership between Guru Investment Company and AVR Labs. Step inside and discover how we turned a simple concept into a cutting-edge marketing tool.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>Immersive Virtual Tour for off plan property.</p>`,
        class: "right",
      },
      {
        title: `Objective`,
        desc: `<p>To visually capture the essence of Guru Investment Company's tech-focused brand and elevate their marketing strategy.</p> `,
      },
      {
        title: `Challenges`,
        desc: `<p>Guru Investment Company faced a unique challenge. They needed a marketing approach that aligned with their forward-thinking, tech-oriented image. Static images fell short of conveying the project's advanced nature.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs crafted a solution that stood out:</p>
                <p>Immersive Virtual Tour for Marketing: We breathed life into static renderings, creating a captivating virtual tour. This wasn't just any tour; it was an embodiment of Guru Investment Company's tech-savvy and progressive identity.</p>`,
        class: "",
      },
      {
        title: `Results`,
        desc: `<p>The Zohour Investment Project yielded remarkable outcomes:</p>
                <p>Tech-Centric Branding: The virtual tour positioned Guru Investment Company as a tech-savvy and cutting-edge brand.</p>
                <p>Engaging Marketing: Prospective investors and clients could now immerse themselves in the project, fosFtering more engaging marketing.</p>
                <p>A Modern Identity: The project underscored Guru Investment Company's dedication to innovation and advanced technology.</p>
                <p>The Zohour Investment Project is a prime example of how AVR Labs transforms a concept into an immersive virtual reality.</p>`,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `<p>Interested in exploring similar opportunities for your brand?</p><p>Let's connect and discuss your vision!</p>`,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 14,
    img: POS15,
    title: "Al Sweidi Villa - AR",
    video: vimeoVideoUrls["P15"],
    slug: "P15",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Overview`,
        desc: `Discover how AVR Labs, a pioneer in Augmented and Virtual Reality solutions, revolutionized the Ahmed’s Villa Project. Our innovative approach is reshaping the architectural landscape, delivering efficiency, accessibility, and a seamless experience.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>Virtual Tour, Vendor Collaboration, Virtual Meeting Space, Real-time Document Management, QR Code Access.</p>`,
        class: "right",
      },
      {
        title: `Objective`,
        desc: `<p>To create a centralized platform for clients and stakeholders, facilitating project access, communication, and collaboration.</p> `,
      },
      {
        title: `Challenges`,
        desc: `<p>The Villa Project posed common challenges in architectural ventures: centralized access to project data and efficient communication with clients and stakeholders.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs harnessed advanced Virtual Reality technology to address these challenges.</p>
                <p>Virtual Tour: We crafted an immersive virtual tour of Ahmed Villa, enabling clients to explore the project without leaving their space. Stakeholders could provide feedback remotely, fostering efficient collaboration.</p>
                <p>Vendor Collaboration: Our platform streamlined vendor collaboration. Vendors could upload documents, blueprints, and images directly to the virtual space, ensuring organized and accessible data.</p>
                <p>Virtual Meeting Space: We introduced a dedicated virtual meeting area. This eliminated the need for physical meetings, allowing stakeholders to convene virtually, discuss project updates, and drive decision-making.</p>
                <p>Real-time Document Management: All project-related documents were stored within the virtual space. Clients and stakeholders accessed real-time updates, promoting transparency and accessibility.</p>
                <p>QR Code Access: An innovative QR code system granted easy access to the virtual space. Stakeholders could immerse themselves in the project's world without relying on lengthy email exchanges.</p>`,
        class: "",
      },
      {
        title: `Results`,
        desc: `<p>This project experienced following transformative outcomes:</p>
                <p>Efficient Communication: Fragmented email communication was replaced by comprehensive discussions within the virtual space.</p>
                <p>Enhanced Collaboration: Vendors, clients, and stakeholders collaborate seamlessly, ensuring a shared vision.</p>
                <p>Real-time Updates: Clients accessed up-to-date project documentation, improving transparency.</p>
                <p>Efficient Meetings: Virtual meetings save time and resources while encouraging productive discussions.</p>
                <p>Immersive Experience: The virtual tour offered an immersive experience, enabling clients to envision the final project through VR devices.</p>
                <p>The Villa Project shows how Virtual Designs Hub is using smart tech to make architecture better.</p>`,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `<p>Want to see how we can help your project? Let's talk!</p>`,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 15,
    img: POS16,
    title: "Joelle Clinic",
    video: vimeoVideoUrls["P16"],
    slug: "P16",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Overview`,
        desc: `The future of aesthetic clinics, where AVR Labs has partnered with Joelle Clinic to revolutionize the way clients engage with their services. Joelle Clinic, based in Dubai, UAE, is renowned for its commitment to transforming lives through the latest aesthetic techniques, cutting-edge technology, and trends.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>Virtual Clinic Tour: AVR Labs introduces a comprehensive virtual platform, enabling users to explore Clinic Joel's interior and exterior realistically.</p>
        <p>Google Street View Integration: Take a virtual stroll around the clinic's surroundings for a unique perspective.</p>
        <p>Immersive Facility Exploration: Beyond a standard virtual tour, this project aims to build client trust through an engaging and immersive experience.</p>`,
        class: "right",
      },
      {
        title: `Objective`,
        desc: `<p>The primary goal is to revamp client engagement in the aesthetic clinic sector, providing an informative and immersive virtual visit experience.</p> `,
      },
      {
        title: `Challenges`,
        desc: `<p>Joelle Clinic sought to enhance their client engagement and provide a unique way for potential customers to explore their clinic virtually. The challenge was to create a dynamic and immersive experience that would not only showcase the clinic's state-of-the-art facilities but also offer valuable insights into their services.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs crafted a practical solution:</p>
                <p>Virtual Clinic Tour: A comprehensive virtual platform for a realistic exploration of Clinic Joel's interior and exterior.</p>
                <p>Google Street View Integration: Virtual strolls around the exterior for a unique perspective on the clinic's location.</p>
                <p>Immersive Facility Exploration: More than just a virtual tour, this immersive experience builds client trust, encourages inquiries, and boosts appointments.</p>`,
        class: "",
      },
      {
        title: `Results`,
        desc: `<p>The Clinic Joel project showcases AVR Labs' commitment to reshaping client experiences in the aesthetic clinic sector. The virtual wellness tour has led to increased client trust, inquiries, and appointments.</p>`,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `<p>Ready to enhance your client engagement? Contact AVR Labs for a personalized demo of our transformative virtual tour experiences.</p>`,
        class: "",
        contactButton: false
      },
    ],
  },
  // {
  //   id: 16,
  //   img: img8,
  //   title: "Ajman Engineering workshop",
  //   video: vimeoVideoUrls["P17"],
  //   slug: "P17",
  //   tags:['Workshops'],
  //   data: [
  //     {
  //       title: `Overview`,
  //       desc: `no text yet.`,
  //     },
  //   ],
  // },
  {
    id: 17,
    img: POS18,
    title: "Zayed Complex Interactive Tour - Ministry of Education",
    video: vimeoVideoUrls["P18"],
    slug: "P18",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs collaborated with the Ministry of Education on the Zayed Complex project to create an immersive Interactive Virtual Tour. The objective was to provide a comprehensive digital experience showcasing the facilities and sustainability initiatives of the complex.`,
      },
      {
        title: `Challenges`,
        desc: `<p>The challenge was to capture the essence of the Zayed Complex and its sustainability strategy while ensuring an engaging and informative virtual tour experience for visitors.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs implemented the following solutions to overcome the challenges:</p>
                <p>Interactive 360° Images: Utilized high-quality 360° images to showcase each room and facility within the complex, allowing users to explore every corner virtually.</p>
                <p>Animation Integration: Added animations to enhance interactivity and engagement, providing users with a dynamic and visually appealing experience.</p>
                <p>Audio-Visual Explanation: Incorporated audio explanations and video clips to provide detailed information about the complex's sustainability initiatives, guiding visitors through the eco-friendly features and practices adopted.</p>`,
        class: "",
      },
      {
        title: `Project Highlights`,
        desc: `<p>The Zayed Complex Virtual Tour stands out as a testament to the Ministry of Education's commitment to sustainability and innovation. The immersive tour allows visitors to gain insight into the complex's facilities and eco-friendly initiatives, fostering awareness and appreciation for sustainable practices.</p>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Virtual reality </li></ul>`,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Ready to enhance your client engagement? Contact AVR Labs for a personalized demo of our transformative virtual tour experiences.</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 18,
    img: POS19,
    title: "Bateel",
    video: vimeoVideoUrls["P19"],
    slug: "P19",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs partnered with Bateel to create an immersive and interactive Virtual Tour of their store. Accessible via Google Maps, this innovative solution provides users with a virtual preview of the store, showcasing precise details of products and the outlet itself. As a certified provider for Google Street View, AVR Labs ensured the highest quality and accuracy in the virtual tour experience.`,
      },
      {
        title: `Challenges`,
        desc: `<p>The challenge was to accurately replicate the physical store environment in the virtual realm, ensuring that users could navigate seamlessly and explore products with ease. Additionally, maintaining consistency and detail while capturing the essence of the Bateel brand posed a significant challenge.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs implemented several solutions to address the challenges faced:</p>
        <p>Precise Imaging: Utilizing advanced imaging technology, AVR Labs captured high-quality, detailed images of the store and products, ensuring a true-to-life virtual experience.</p>
        <p>Seamless Navigation: By integrating user-friendly navigation features, such as clickable hotspots and intuitive controls, AVR Labs enabled users to explore the virtual tour effortlessly.</p>
        <p>Brand Consistency: Through careful curation of visuals and branding elements, AVR Labs maintained consistency with the Bateel brand identity, reinforcing brand recognition and trust.</p>`,
        class: "",
      },
      {
        title: `Project Highlight`,
        desc: `<p>The highlight of the project was the seamless integration of the virtual tour into Google Maps, providing users worldwide with access to an immersive and informative experience. From browsing products to exploring the store layout, the virtual tour offers a captivating glimpse into the world of Bateel.</p>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Virtual reality </li></ul>`,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Book your consultation today to discover how our services can elevate your business to new heights!</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 19,
    img: POS20,
    title: "NFT El Rabe3",
    video: vimeoVideoUrls["P20"],
    slug: "P20",
    tags:[],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs partnered with El Rabe3 to create a unique collection of NFTs of pixelated avatars for them. The project aimed to bring innovation and creativity to the world of non-fungible tokens (NFTs) through distinctive pixel art avatars.`,
      },
      {
        title: `Challenges`,
        desc: `<p>The challenge lay in crafting pixelated avatars that not only captured the essence of El Rabe3 but also stood out in the competitive NFT market. Maintaining the integrity of the brand while infusing creativity into each avatar was paramount.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs devised a solution that combined artistic flair with technical expertise:</p>
        <p>Pixel Art Creation: Utilized advanced pixel art techniques to design unique and eye-catching avatars that reflected the personality of El Rabe3.</p>
        <p>Blockchain Integration: Leveraged blockchain technology to ensure the authenticity, ownership, and scarcity of each pixelated avatar within the NFT collection.</p>`,
        class: "",
      },
      {
        title: `Project Highlight`,
        desc: `<p>The highlight of the NFT El Rabe3 project was the seamless integration of pixel artistry with blockchain technology, resulting in a collection of NFTs that captured attention and sparked interest among collectors and enthusiasts alike.</p>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<ul><li>- Blockchain Technology</li></ul>`,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Get your own NFT collection now! Contact us for expert guidance.</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 20,
    img: POS21,
    title: "Private House Interactive Design",
    video: vimeoVideoUrls["P21"],
    tags:['Interactive Virtual Tour'],
    slug: "P21",
    data: [
      {
        title: `Overview`,
        desc: `The Harmony House, where AVR Labs has revolutionized how we experience architecture. Forget about the usual virtual tours; this is an immersive journey that transforms buildings into something extraordinary. Harmony House is a showcase of how AVR Labs makes architecture not just interesting but downright captivating. It's not magic, but it feels like it as we change the way you perceive buildings.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>AR Virtual Tour: AVR Labs takes you beyond the ordinary with an augmented reality wonderland, letting you explore architectural marvels interactively.</p>
        <p>Architectural Showcase: Witness architectural designs come to life through a captivating showcase, blurring the lines between reality and digital wonder.</p>
        <p>Interactive Design Exploration: An AR experience turns exploration into an enchanting journey, engaging you beyond traditional virtual tours.</p>`,
        class: "right",
      },
      {
        title: `Objective`,
        desc: `<p>Redefine the virtual tour experience, providing visitors with a unique and captivating journey through augmented reality.</p> `,
      },
      {
        title: `Challenges`,
        desc: `<p>Enhancing architectural exploration presented a unique challenge, requiring an innovative solution to captivate users and provide a compelling AR virtual tour.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs responded with a captivating solution:</p>
        <p>AR Virtual Tour: Users can now immerse themselves in an interactive exploration of architectural wonders, revolutionizing the traditional virtual tour.</p>
        <p>Architectural Showcase: A dynamic showcase brings architectural designs to life, creating an immersive experience that goes beyond expectations.</p>
        <p>Interactive Design Exploration: The AR journey introduces users to an enchanting exploration, blurring the boundaries between the physical and digital realms.</p>
        </p>`,
        class: "",
      },
      {
        title: `Results`,
        desc: `<p>Harmony House stands as proof of AVR Labs' dedication to elevating architectural exploration. Users enjoy an AR journey that surpasses traditional virtual tours, fostering engagement and leaving a lasting impression.</p>`,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `<p>Elevate your architectural experiences with AVR Labs. Contact us to embark on captivating AR journeys.</p>`,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 21,
    img: POS22,
    title: "World Future Energy Summit",
    video: vimeoVideoUrls["P23"],
    slug: "P22",
    tags:['Virtual Reality'],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs collaborated with the organizers of the World Future Energy Summit, hosted at ADNEC, to enhance the attendee experience through innovative AI and AR solutions. The aim was to provide seamless navigation within the venue and offer an immersive virtual tour showcasing exhibits and sessions.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>AR Navigation System: AVR Labs developed a custom AR navigation app, enabling visitors to scan QR codes placed throughout the venue for precise navigation to their destinations.</p>
        <p>AI Avatar in Augmented Reality: An AI-powered avatar appears in augmented reality, allowing visitors to interact and have natural conversations to obtain information about the event.</p>
        <p>Immersive Virtual Tour: A comprehensive virtual tour was created, integrating media, videos, and maps to provide attendees with an immersive exploration experience of the summit.</p>
        <p>Multimedia Integration: The virtual tour included multimedia elements to showcase exhibitor booths, keynote sessions, and interactive displays, enriching attendee engagement and understanding.</p>
        <p>User-friendly Mobile App: The AR navigation system, AI avatar, and virtual tour were seamlessly integrated into a user-friendly mobile application, ensuring easy access and navigation for all attendees.</p>
        `,
        class: "right",
      },
      {
        title: `Challenges`,
        desc: `<p>Venue Complexity: ADNEC's vast venue posed challenges in wayfinding and navigating between different zones and exhibits.</p>
        <p>Information Overload: With numerous sessions and exhibitors, attendees faced difficulty in accessing relevant information efficiently.</p>
        <p>Engagement Expectations: The summit aimed to provide a memorable and engaging experience, necessitating innovative solutions to captivate and educate attendees effectively.</p> 
        `,
      },
      {
        title: `Solution Provided`,
        desc: `
        <p>AR Navigation: Designed a customized AR navigation system, simplifying wayfinding with clear directions and real-time guidance for attendees.</p>
        <p>AI Avatar in Augmented Reality: Implemented an AI-powered avatar in augmented reality to interact with attendees, providing real-time information and assistance, enhancing the overall event experience.</p>
        <p>Immersive Virtual Experience: Developed an immersive virtual tour offering attendees dynamic exploration, allowing them to interact with summit highlights at their own pace.</p>
        <p>Rich Multimedia Content: Integrated multimedia elements into the virtual tour to provide engaging and informative content, enhancing attendee experience and understanding.</p>
        <p>User-centric Design: Prioritized user experience throughout the project, focusing on intuitive design and functionality to meet the diverse needs of summit attendees.</p>
        `,
        class: "right",
      },
      {
        title: `CTA`,
        desc: `<p>The collaboration between AVR Labs and the World Future Energy Summit organizers resulted in a successful event experience, empowering attendees with innovative AI and AR solutions for seamless navigation and immersive exploration of future technologies and innovations.</p>
        `,
        class: "",
        contactButton: false
      },
    ],
  },
  {
    id: 22,
    img: POS23,
    title: "Tech Craft Platform Development",
    video: vimeoVideoUrls["P22"],
    slug: "P23",
    tags:['Augmented Reality'],
    data: [
      {
        title: `Overview`,
        desc: `AVR Labs collaborated with Tech Craft, a leading provider of electronics products in the UAE, to create an innovative virtual platform. This project aimed to enhance user experience by developing an interactive virtual website that showcases Tech Craft’s extensive product range, including electrical lab equipment, providing a seamless and immersive experience.`,
      },
      {
        title: `Key Feature`,
        desc: `<p>Virtual Website: An immersive platform displaying Tech Craft’s diverse electronics products.</p>
        <p>Product Showcase: Detailed product descriptions and 3D views of electronic components.</p>
        <p>User-Friendly Interface: Intuitive navigation and interactive elements for a seamless user experience.</p>
        <p>Augmented Reality: "View it in your space" feature to try the product in real-time instantly.</p>
        `,
        class: "right",
      },
      {
        title: `Objectives`,
        desc: `<p>Enhance User Engagement: Create an engaging virtual platform to attract and retain customers.</p>
        <p>Showcase Expertise: Effectively demonstrate Tech Craft’s comprehensive product range.</p>
        <p>Improve Accessibility: Make Tech Craft’s products accessible to a global audience.</p> 
        <p>Support Digital Transformation: Align with Tech Craft’s digital transformation goals by leveraging advanced technologies.</p> 
        `,
      },
      {
        title: `Solution`,
        desc: `
        <p>AVR Labs developed a comprehensive virtual platform for Tech Craft, integrating advanced technologies to create an immersive and interactive user experience. The platform features:</p>
        <p>Virtual Website: Showcasing Tech Craft’s diverse range of electronics products with detailed descriptions and 3D views.</p>
        <p>Interactive Product Showcase: Providing detailed product descriptions and interactive 3D views for an engaging shopping experience.</p>
        <p>Augmented Reality: Allowing customers to use the "view it in your space" feature to try products in real-time.</p>
        `,
        class: "right",
      },
      {
        title: `Results`,
        desc: `
        <p>Increased Engagement: The virtual platform has significantly enhanced user engagement, attracting more visitors and retaining them longer.</p>
        <p>Global Reach: Tech Craft’s products and services are now accessible to a worldwide audience, boosting international interest.</p>
        <p>Enhanced Brand Perception: Tech Craft is now perceived as a technologically advanced and customer-centric brand.</p>
        <p>Partnering with AVR Labs, Tech Craft has successfully transformed its digital presence, providing an innovative and interactive platform that meets the needs of its diverse customer base and supports its growth objectives.</p>
        `,
        class: "",
      },
    ],
  },
  {
    id: 23,
    img: POS24,
    title: "Transforming Real Estate Sales with VR  BnW Developments",
    video: vimeoVideoUrls["P24"],
    slug: "P24",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Project Overview`,
        desc: `In the rapidly evolving world of real estate marketing, BnW Developments has taken a bold step forward by leveraging the power of Virtual Reality (VR). This cutting-edge technology is not just about gaming or training; they are powerful tools that revolutionize how properties are presented, stories are told, and customer engagement is achieved.`,
      },
      {
        title: `Objectives`,
        desc: `<p>Enhance Property Presentation: Use VR  to create immersive property experiences, allowing potential buyers to explore homes in a highly engaging and interactive way.</p>
        <p>Build Immersive Brand Stories: Develop compelling narratives around properties to captivate and inspire potential buyers.</p>
        <p>Promote Properties Through Immersive Experiences: Leverage VR and AR to provide unforgettable experiences that differentiate BnW Developments in a competitive market.</p>
        <p>Facilitate Understanding of Complex Properties: Utilize technology to simplify and clarify property features, layouts, and amenities.</p>
        <p>Capture Customer Attention: Engage potential buyers with unique and innovative experiences that leave a lasting impression.</p>
        `,
        class: "right",
      },
      {
        title: `Challenges`,
        desc: `<p>Engaging Remote Buyers: Traditional marketing tools were insufficient to fully engage potential buyers who could not visit the sales center in person.</p>
        <p>Non VR users: Create a virtual experience on touch screens rather than the VR device.</p>
        <p>Competitive Market: Standing out in a crowded real estate market required innovative and memorable marketing strategies.</p> 
        <p>Expand reach: Building of a Web Virtual experience that is easily sharable on the web allowing prospects to view the future property from their own devices.</p> 
        `,
      },
      {
        title: `Solutions Provided`,
        desc: `
        <p>Interactive Virtual Tour: Potential buyers can take a virtual walk-through the future properties, exploring every detail from the comfort of their own homes. This solution addressed the challenge of engaging remote buyers.</p>
        <p>Interactive Screens for Visitors: Interactive displays at sales centers provide an engaging way for visitors to interact with property details and visualize spaces, simplifying the presentation of complex property features.</p>
        <p>VR Headsets: VR headsets offer an immersive experience, transporting potential buyers directly into the properties. This solution enhanced customer understanding and emotional connection, addressing both the complexity and connection challenges.</p>
        `,
        class: "right",
      },
      {
        title: `Key Achievements`,
        desc: `
        <p>BnW Developments successfully utilized XR technologies to sell a stunning property on Al Marjan Island, Ras Al Khaimah. The project demonstrated how these modern tools can significantly enhance the marketing and sales process, providing potential buyers with an unforgettable experience.</p>
        <p>This groundbreaking project was made possible through the collaboration of expert teams and visionary leaders from BnW Development. Together, we have created an unforgettable experience for potential buyers, setting a new standard in real estate marketing.</p>
        <p>Discover how VR and AR can transform your real estate marketing strategy.</p>
        `,
        class: "",
      },
    ],
  },
  {
    id: 24,
    img: POS25,
    title: "Kinect-Powered Interactive Game for GMG Event",
    video: vimeoVideoUrls["P25"],
    slug: "P25",
    tags:['Interactive Virtual Tour'],
    data: [
      {
        title: `Project Overview`,
        desc: `AVR Labs collaborated with GMG, a leading conglomerate in healthcare, cosmetics, and pharmacy, to create a captivating interactive game for their event using Kinect Technology. The objective was to engage diverse attendees with an innovative gaming experience that aligned with GMG’s brand identity.`,
      },
      {
        title: `Challenges`,
        desc: `<p>GMG needed a solution that could appeal to a varied audience while maintaining relevance to their multiple brands.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs developed a Kinect-based interactive game, allowing attendees to engage without needing wearable devices. This approach offered a seamless, hands-free experience, encouraging active participation and deeper brand engagement.</p> `,
      },
      {
        title: `Project Highlights`,
        desc: `<p>The game became a central attraction, drawing participants from various industries and reinforcing brand presence through memorable interactions.</p>`,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: `<p>- Kinect Technology
                  - Interactive Gaming Platform.
              </p>
              <p>Elevate your event with immersive, interactive Kinect games.</p>
              `,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>If you're interested in exploring the future of art with AVR Labs and experiencing interactive art in your way, reach out to us to start the conversation.</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 25,
    img: POS26,
    title: "AI Hologram & VR Experiences at Saudi Event Show",
    video: vimeoVideoUrls["P26"],
    slug: "P26",
    tags:['Interactive Virtual Tour'],
    helmetHead: true,
    data: [
      {
        title: `Project Overview`,
        desc: `In collaboration with Serotonin Technologies, AVR Labs delivered a futuristic digital experience for the Saudi Event Show. Bringing the WoW factor with our AI Avatar on a Hologram to captivate the audience. along with a VR and VT experiences to take visitors to places beyond the show itself.`,
      },
      {
        title: `Challenges`,
        desc: `<p>Serotonin Technologies needed to merge multiple advanced technologies into one unified setup to enhance brand engagement and captivate attendees, all while ensuring the technologies worked seamlessly together during a live event.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>We provided an AI Virtual Being in hologram form, offering real-time interactions. Visitors also experienced intuitive engagement through our Virtual Reality and immersive Virtual tours.</p> `,
      },
      {
        title: `Project Highlights`,
        desc: `
        <p>- AI-powered holographic virtual being interacting with visitors in real time.</p>
        <p>- Custom virtual website to showcase products and services.</p>
        <p>- Interactive virtual experiences enhancing attendee engagement.</p>
        <p>- Successful deployment of multiple technologies for a smooth event experience.</p>
        `,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: ` <p>- AI-powered hologram (Virtual Being)</p>
                <p>- Interactive Virtual Reality powered and represented by AI</p>
                <p>- Custom virtual website creation</p>
              `,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Looking to enhance your brand experience with cutting-edge technology?
        Contact Us today and discover how we can bring your vision to life!</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 26,
    img: POS27,
    title: "AR & 3D Visualization for Event Rentals",
    video: vimeoVideoUrls["P27"],
    slug: "P27",
    tags:['Interactive Virtual Tour'],
    helmetHead: true,
    data: [
      {
        title: `Project Overview`,
        desc: `AVR Labs collaborated with Space ER to develop a virtual store featuring AR-enabled 3D product visualization, transforming their event rental services. The project enhanced Space ER’s digital presence by enabling customers to view and interact with products in immersive 3D before making rental decisions.`,
      },
      {
        title: `Challenges`,
        desc: `<p>Space ER has a big and wide range of Event furniture and it was impossible to show case it all in a single event. Sought a way to showcase their premium event rental items more interactively, beyond traditional catalogs, while providing potential clients a virtual experience of their unique furniture designs and setups in a way close to reality.</p>`,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `<p>AVR Labs integrated Augmented Reality (AR) and 3D visualization technologies into a custom virtual store. This allowed Space ER’s clients to explore event setups and rental items in an interactive 3D models, creating a lifelike preview of the event spaces.</p> `,
      },
      {
        title: `Project Highlights`,
        desc: `
        <p>- AR-powered 3D visualizations of event setups and furniture.</p>
        <p>- Seamless virtual browsing experience with interactive product engagement.</p>
        <p>- Increased customer engagement through immersive, hands-on product interaction.</p>
        `,
        class: "right",
      },
      {
        title: `Technology Used`,
        desc: ` <p>- Augmented Reality (AR) - ARViewz</p>
                <p>- 3D Product Visualization - Virtual Designs Hub</p>
                <p>- Custom virtual store platform</p>
              `,
        class: "",
      },
      {
        title: `CTA`,
        desc: `<p>Ready to transform your event rental experience with cutting-edge AR solutions? Contact AVR Labs today and discover how we can bring your space designs to life!</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 27,
    img: POS28,
    title: "Grandseal Solutions",
    video: vimeoVideoUrls["P28"],
    slug: "P28",
    tags:['Interactive Virtual Tour'],
    helmetHead: true,
    data: [
      {
        title: `Overview`,
        desc: `Grandseal Solutions, a leading Dubai-based provider of high-quality building and waterproofing materials, is known for addressing diverse market needs while balancing cost-efficiency with quality assurance. Participating in the prestigious Big 5 Event, Grandseal aimed to make a lasting impact on their audience through an engaging and innovative presentation of their solutions. AVR Labs collaborated with Grandseal to deliver a transformative solution to enhance their event presence.`,
      },
      {
        title: `Key Features of the Project`,
        desc: `
        <h6>
          1. Tangible Table Technology
        </h6>
        <ul class="main-theme-order">
          <li>
            Interactive display integrated with cutting-edge tangible table technology for showcasing Grandseal's product range.
          </li>
          <li>
            Real-time 3D models of waterproofing and building materials to provide a tactile and visual exploration for visitors.
          </li>
        </ul>
        <h6>
          2. Immersive Experience Design
        </h6>
        <ul class="main-theme-order">
          <li>
            Enhanced user engagement with an intuitive interface, allowing visitors to interact with Grandseal’s offerings in a virtual yet tangible environment.
          </li>
          <li>
            Seamless navigation through product specifications and features with AR overlays.
          </li>
        </ul>
        <h6>
          3. Event Impact Optimization
        </h6>
        <ul class="main-theme-order">
          <li>
            Eye-catching, futuristic setup to draw attention and stand out among competitors.
          </li>
          <li>
          Designed to ensure product recall and encourage interaction, fostering stronger client relationships.
          </li>
        </ul>
        `,
        class: "right",
      },
      {
        title: `Challenges`,
        desc: `
          <h6>
            Standout Presence at Big 5
          </h6>
          <p>
            Competing in a high-profile event required a unique and memorable experience to distinguish Grandseal from industry peers.
          </p> 
          <h6>
            Complex Product Presentation
          </h6>
          <p>
            Conveying intricate product details and technical specifications in a manner that was engaging and easily understood.
          </p>
          <h6>
            Audience Engagement
          </h6>
          <p>
            Capturing the attention of a diverse audience while maintaining a professional and innovative tone.
          </p> 
        `,
      },
      {
        title: `Solution Provided by AVR Labs`,
        desc: `
          <p>Our tech experts crafted a bespoke <strong>tangible table solution</strong> tailored to Grandseal’s requirements, enabling them to showcase their products dynamically at the Big 5 Event.</p>
          <h6>
            Interactive Tangible Table
          </h6>
          <p>
            An engaging platform allowed visitors to explore waterproofing and building materials virtually while maintaining the tactile experience crucial to the construction industry.
          </p> 
          <h6>
            3D Visualization
          </h6>
          <p>
            3D models displayed product details and real-life applications, simplifying complex information for visitors.
          </p>
          <h6>
            Innovative Design for Event Appeal
          </h6>
          <p>
            The futuristic setup not only captivated attendees but also underscored Grandseal’s commitment to innovation and quality.
          </p> 
        `,
        class: "right",
      },
      {
        title: `Summary`,
        desc: `
        <p>
          By leveraging our tangible table solution, Grandseal Solutions achieved a standout presence at the Big 5 Event, captivating their audience with an engaging, tech-driven experience. The solution effectively communicated their product’s value propositions, fostering deeper connections with potential clients while setting a new standard for innovation in event technology.
        </p>
        `,
        class: "",
      },
      {
        title: `Let’s Make Your Vision a Reality!`,
        desc: `<p>Want to turn heads and create impactful experiences at your next event? Reach out to us today!</p>`,
        class: "right",
        contactButton: false
      },
    ],
  },
  {
    id: 28,
    img: POS29,
    title: "Immersive Room Solutions: Transform Spaces into Unforgettable Experiences",
    video: vimeoVideoUrls["P29"],
    slug: "P29",
    tags:['Interactive Virtual Tour'],
    helmetHead: true,
    data: [
      {
        title: `Project Overview`,
        desc: `At AVR Labs, we revolutionize how you engage your audience by turning spaces into immersive, interactive environments. Using cutting-edge projection mapping and immersive technology, we create dynamic visuals that captivate and connect. Whether for events, branding, or entertainment, our solutions offer a WOW-worthy experience that sets you apart and leaves a lasting impression.`,
      },
      {
        title: `Pain Points We Solve:`,
        desc: `
        <ul class="main-theme-order">
          <li>
            <strong>Lack of Engagement:</strong>
            Traditional setups often fail to capture attention, leaving your audience unengaged and disconnected.
          </li>
          <li>
            <strong>Visual Clutter:</strong>
            Competing for attention in crowded spaces with overused and uninspired designs.
          </li>
          <li>
            <strong>Missed Opportunities for Impact:</strong>
            Struggling to convey your message in a memorable and meaningful way.
          </li>
          <li>
            <strong>Integration Challenges:</strong>
            Difficulty incorporating innovative technology into existing environments seamlessly.
          </li>
        </ul>
        `,
        class: "right",
      },
      {
        title: `Solution`,
        desc: `
        <ul class="main-theme-order">
          <li>
            <strong>Immersive Visuals:</strong>
            Bring your walls and floors to life with stunning projections that transform ordinary spaces into extraordinary, interactive experiences.
          </li>
          <li>
            <strong>Multi-Sensory Environments:</strong>
            Combine visuals, sound, and interactivity to create environments that captivate the senses and resonate emotionally.
          </li>
          <li>
            <strong>Custom Designs:</strong>
            Each immersive room is tailored to your specific goals, whether it's driving brand awareness, hosting unforgettable events, or creating customer delight.
          </li>
          <li>
            <strong>Effortless Integration:</strong>
            Our solutions fit seamlessly into your space without disrupting functionality or design, ensuring a smooth and hassle-free experience.
          </li>
          <li>
            <strong>Standout Appeal:</strong>
            Capture attention and outshine competitors with bold, innovative presentations that wow your audience.
          </li>
        </ul>
        `,
      },
      {
        title: `Project Highlights`,
        desc: `
        <p>AVR Labs' immersive room solutions go beyond visuals to create transformative experiences that engage, inspire, and connect. Whether you're looking to elevate an event, redefine branding, or offer unforgettable storytelling, our technology brings your vision to life with unparalleled impact.</p>
        `,
        class: "right",
      },
      {
        title: `Ready to WOW Your Audience?`,
        desc: `<p>Contact us today and take the first step toward unforgettable experiences!</p>`,
        class: "",
        contactButton: false
      },
    ],
  },
];

export default dataItem;
