import React from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";
import { useParams } from "react-router-dom";
import dataProject from "../assets/data/data-project";
import ProjectInfo from "../components/project/ProjectInfo";
import RelatedProjects from '../components/project/RelatedProjects';
import Create from '../components/create/Create2';
import contactImg from '../assets/Services/have-a-project2.png';

function ProjectDetails() {
  const { slug } = useParams();
  const project = dataProject.find((project) => project.slug === slug);
  if(!project){
    window.location.href = "/projects";
    return null;
  }
  const contactData = {
    heading: 'Have a project?',
    desc: 'Book your FREE Consultation now to transform your business before it’s too late!',
    btn: true,
    btnTitle: 'Contact us',
    link: '/contact',
    img: contactImg
  }
  const heading = project.title;
  const subheading = "Project Details";
  return (
    <div className="page-roadmap wrapper pt-6">
      <PageTitle title="Project Details" menu='Projects' link="/projects" />
      <ProjectInfo data={project.data} heading={heading} subheading={subheading} tags={project.tags} />
      <section className="project">
        <div className="shape right"></div>
        <div className="container">
          <div className="row">
            {project.video && (
              <div className="col-xl-12 col-md-12">
                <div className="project-box">
                    <div style={{ padding: '56.25% 0 0 0',position: 'relative' }}>
                    <iframe
                        src={project.video}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                        title={project.title}
                        className="embed-responsive-item"
                    ></iframe>
                    </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Create style={{ marginTop: "100px" }} data={contactData} />
      <RelatedProjects data={dataProject} project={project} />
      <Footer />
    </div>
  );
}

export default ProjectDetails;
