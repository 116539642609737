
import beauty from '../solutions/Virtual try on//Final Icons/Competitive Advantage.png';
import accessories from '../solutions/Virtual try on/Final Icons/Convenient Shopping.png';
import eyewear from '../solutions/Virtual try on/Final Icons/Informed Decisions.png';
import personalized from '../solutions/Virtual try on/Final Icons/Personalized Experience.png';
import time from '../solutions/Virtual try on/Final Icons/Time and Cost Efficiency.png';


const dataBox = [

    {
        id: 1,
        img: beauty,
        title: 'Convenient Shopping',
        desc: ''
    },
    {
        id: 2,
        img: accessories,
        title: 'Informed Decisions',
        desc: ''
    },
    {
        id: 3,
        img: eyewear,
        title: 'Personalized Experience',
        desc: ''
    },
    {
        id: 4,
        img: personalized,
        title: 'Time and Cost Efficiency',
        desc: ''
    },
    {
        id: 5,
        img: time,
        title: 'Competitive Advantage',
        desc: ''
    },
]

export default dataBox;