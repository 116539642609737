const data = [

    {
        id: '',
        title: 'Technology',
        desc: `Motion sensors (e.g., Microsoft Kinect) or infrared cameras with software that translates movements into game interactions.`
    },
    {
        id: '',
        title: 'Applications',
        desc: `Great for event installations, public spaces, and entertainment venues. Touchless games offer a hygienic and interactive experience where players control the game with gestures alone.`
    },
]

export default data;