const dataInteractive = [

    {
        id: '',
        title: 'Technology',
        desc: `Touchscreen technology, gesture recognition, capacitive touch sensors, and software like Touch Designer or Unity 3D.`
    },
    {
        id: '',
        title: 'Applications',
        desc: `Ideal for product demonstrations, interactive guides in hotels, and collaborative tools in offices. Allow users to directly interact with digital content, enhancing engagement.`
    },
]

export default dataInteractive;