import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import defaultHeaderVideo from "../../assets/Services/header.mp4";

function ServiceBanner(props) {
    const { data } = props;
    const videoSource = data?.headerVideo || defaultHeaderVideo;

    return (
        <section className="banner top-banner s2 height-85vh" style={{ position: "relative" , }}>
            {(videoSource && !data.headerIframe) && (<video autoPlay loop muted playsInline style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", zIndex: -1 }}>
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>)}
            {data.headerIframe && (
                <iframe
                    src={data.headerIframe}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", zIndex: 9 }}
                    title={data.headerIframe}
                    className="embed-responsive-item"
                ></iframe>
            )}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center aligned-center-div">
                            {data.heading && <h2 className="heading pd">{data.heading}</h2>}
                            {data.subheading && <h6 className="sub-heading"><span>{data.subheading}</span></h6>}
                            {data.desc && <p className="mb-2" dangerouslySetInnerHTML={{ __html: data.desc}}></p>}
                            {data.desc1 && 
                                <p className="mb-2" dangerouslySetInnerHTML={{ __html: data.desc1 }}>
                                    {/* <span className='span-inside-both'>
                                        {data.span1}
                                    </span>
                                    <span>
                                        {data.span2}
                                    </span>
                                    <span className='span-inside-both'>
                                        {data.span3}
                                    </span> */}
                                    
                                </p>
                            }
                            {data.desc2 && <p className="mb-2">{data.desc2}</p>}
                            {data.button && <Button title={data.btnTitle} link={data.link} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

ServiceBanner.propTypes = {
    data: PropTypes.object
};

export default ServiceBanner;
