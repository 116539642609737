import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';
import portfolioImg from '../../assets/images/layouts/portfolio1.png';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

SolutionFaqs.propTypes = {
    data: PropTypes.array
};
SolutionFaqs.defaultProps = {

    dataBlock: {
        subheading: 'FAQs',
        heading: 'Frequently Aksed Questions',
        desc1: 'Below is a list of frequently asked questions and answers from partners and 3D artist',
        desc2: 'Please check this FAQ first before contacting us.',
        img: portfolioImg

    }
}
function SolutionFaqs(props) {

    const { data, dataBlock } = props;
    return (
        <section className={`faq ${dataBlock.img ? 's3' : ''}`}>
            {!dataBlock.img && <div className="shape right"></div>}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center" data-aos="fade-down" data-aos-duration="3000">
                            {dataBlock.subheading && <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>}
                            {dataBlock.heading && <h3 className="heading" dangerouslySetInnerHTML={{ __html: dataBlock.heading }} />}
                            {dataBlock.desc1 && <p className="mb-17">{dataBlock.desc1}</p>}
                            <p>{dataBlock.desc2}</p>
                        </div>

                        <div className="faq__main flat-tabs">

                            <Tabs>
                                <TabList className="d-none">
                                    <Tab></Tab> 
                                </TabList>
                                <TabPanel className={`content-tab ${data.length <= 3 ? 'centered-content' : ''}`}>
                                    <div className="content-inner">
                                    {dataBlock.img && <div className="flat-accordion row">
                                            <div className='col-xl-6 col-md-12'>
                                                {dataBlock.img && <div className="image" data-aos="fade-left" data-aos-duration="2000">                                                  
                                                    <img src={dataBlock.img} alt="AVRLabs"/>
                                                </div>}
                                            </div>
                                            <div className={`col-xl-6 col-md-12 d-flex align-items-center`}>
                                                <div>
                                                    {data.slice(0, 8).map(idx => (
                                                        <Accordion show={idx.show} key={idx.id} title={idx.title} className='flat-toggle h6'>
                                                            <div className="toggle-content" dangerouslySetInnerHTML={{ __html: idx.desc }} />
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </div>
                                           
                                        </div>}
                                        {!dataBlock.img && <div className="flat-accordion row">
                                            <div className={`col-md-${data.length <= 3 ? '12' : '6'} col-sm-12`}>
                                                <div>
                                                    {data.slice(3, 8).map(idx => (
                                                        <Accordion show={idx.show} key={idx.id} title={idx.title} className='flat-toggle h6'>
                                                            <div className="toggle-content" dangerouslySetInnerHTML={{ __html: idx.text }} />
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`col-md-${data.length <= 3 ? '12' : '6'} col-sm-12`}>
                                                <div>
                                                    {data.slice(0, 3).map(idx => (
                                                        <Accordion show={idx.show} key={idx.id} title={idx.title} className='flat-toggle h6'>
                                                            <div className="toggle-content" dangerouslySetInnerHTML={{ __html: idx.text }} />
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </div>
                                          
                                        </div>}
                                       
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default SolutionFaqs;