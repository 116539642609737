const data = [

    {
        id: '',
        title: 'Technology',
        desc: `Motion tracking sensors, 3D graphics, immersive audio, and infrared sensors for a fully immersive experience.`
    },
    {
        id: '',
        title: 'Applications',
        desc: `Perfect for virtual tours, training programs, product demos, and entertainment. Engage your audience in a completely virtual world for a truly captivating experience.`
    },
]

export default data;