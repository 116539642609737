const data = [

    {
        id: '',
        title: 'Technology',
        desc: `A mix of projectors, surround sound, interactive displays, and optional VR/AR technologies, with sensors that adjust the environment based on user input.`
    },
    {
        id: '',
        title: 'Applications',
        desc: `Perfect for training sessions, virtual reality experiences, art installations, and immersive storytelling. These rooms create fully engaging, transformative environments that transport users to new realities.`
    },
]

export default data;