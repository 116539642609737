
const dataTeam = [

    {
        id: 1,
        img: '',
        name: 'Raed Mikail',
        position: 'CEO',
        desc:'The Metaverse And XR Guru, Our 15 Years Management Expert Founder And CEO Has A Track Record Of Successful Business Management And Development Projects.',
        desc2:'He Started As An Interior & Environmental Designer, But His Passion For Innovation And Immersive Technologies Led Him To Expand His Knowledge And Expertise By Earning Certificates In Blockchain And XR Development, With A Mission To Accelerate The Adoption Of XR And Revolutionize The Way People Do Business And Help The To Be Part Of This Immersive Digital World.',
        iframe:'https://portal.arviewz.com/products/206/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },  
    {
        id: 2,
        img: '',
        name: 'Ahmad Al-Serjaawi',
        position: 'OPERATION MANAGER',
        desc:'Our Modern And Artistical Eye, Ahmad Is An Experienced Architect And Interior Designer Proficient In Many Aspects Whether In Creating Comprehensive Interior And Exterior Concepts Or Utilizing Augmented Reality And Virtual Reality Technologies In Design And Visualization.',
        desc2:'Ahmad Has A Deep Understanding Of How These Technologies Can Enhance The Architectural Design Process And Has A Proven Track Record Of Delivering Innovative And Visually Stunning Projects To Our Clients.',
        iframe:'https://portal.arviewz.com/products/207/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },
    {
        id: 3,
        img: '',
        name: 'Bilal Naseer',
        position: 'Senior FULL STACK LEAD DEVELOPER',
        desc:'Bilal Is A Talented And Dedicated Senior Full-Stack Developer With 6 Years Of Experience In Creating Websites And Virtual Design, And Proven Expertise In Developing Innovative And User-Friendly Solutions, Such As E-Commerce Websites, Rest Api’s, Vue Js, ReactJs, Angular, AWS, JavaScript, And More.',
        desc2:'Bilal Approaches Each Project With Careful Scrutiny And A Focus On Delivering Exceptional Results For Our Clients.',
        iframe:'https://portal.arviewz.com/products/210/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },
    {
        id: 4,
        img: '',
        name: 'Hanna Deeb',
        position: 'CREATIVE DIRECTOR',
        desc:'Creating Compelling Visuals That Speak Directly To The Reader, Viewer, Or User. He Also Ensures The Department Follows Production, Productivity, And Quality Standards As Well As Customer Service Guidelines.',
        desc2:'With Over 6 Years Of Experience In Creating And Developing Brands In Fields Like: Technology, Hospitality, Education And Many More.',
        iframe:'https://portal.arviewz.com/products/208/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },  
    {
        id: 5,
        img: '',
        name: 'Prince Bhatia',
        position: 'SENIOR DEVELOPER',
        desc:'Prince Is A Talented And Innovative Three.Js Developer With A Passion For Creating Dynamic And Engaging Virtual And Augmented Experiences.',
        desc2:'With Expertise In Digital Design And Passion For Uniqueness, Prince Has Honed Their Skills In Developing Intricate 3D Models That Bring A Sense Of Realism And Personality To Any Project.',
        iframe:'https://portal.arviewz.com/products/211/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },
    {
        id: 6,
        img: '',
        name: 'Bakhtawar Shah',
        position: 'DIGITAL MARKETING SPECIALIST',
        desc:'Bakhtawar Is A Digital Marketing Specialist With Over 3 Years Of Industry Experience. She’s Demonstrated A Track Record In Social Media Management, SEO, And PPC Advertising, Resulting In Substantial Growth In Conversions And Revenue. Her Data-Driven Approach Has Consistently Delivered Measurable Boosts In Key Performance Metrics Across Various Campaigns, Reflecting Her Results-Oriented Strategy.',
        desc2:'Beyond Marketing, She Aspires To Lead And Potentially Launch A Digital Consultancy, Aiming To Transform Businesses And Practices.',
        iframe:'https://portal.arviewz.com/products/209/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },
    {
        id: 7,
        img: '',
        name: 'Nisreen',
        position: 'General Manager',
        desc:'Nisreen Rashid spearheads sales and business development with over 20 years of expertise in corporate training, project management, and leadership coaching. Armed with an Executive MBA and certifications in sales training and business psychology, she drives strategic growth by fostering innovation and building lasting client relationships. With a proven track record of success working with global organizations like Almarai, Dubai Taxi, and the UN/WTO, Nisreen is pivotal in advancing AVR Labs’ vision as a leader in cutting-edge solutions.',
        desc2:'',
        iframe:'https://portal.arviewz.com/products/212/modelviewer?logoMobile=20&logoDesktop=20&copyRight=block'
    },
    {
        id: 8,
        img: '',
        name: 'Maria',
        position: 'Creative Strategist',
        desc:'Maria is a creative content strategist at AVR Labs, specializing in concept creation and developing innovative solutions that resonate with audiences and enhance brand presence. With a background in filmmaking and scriptwriting, she brings stories and ideas to life with passion and attention to detail.Looking ahead, Maria aspires to write and direct films, create advertisements, and experiment with emerging mediums like virtual reality, interactive experiences, and installation art, pushing the boundaries of storytelling and connecting with audiences in new ways.',
        desc2:'Beyond Marketing, She Aspires To Lead And Potentially Launch A Digital Consultancy, Aiming To Transform Businesses And Practices.',
        iframe:'https://portal.arviewz.com/products/976/modelviewer?logoMobile=100&logoDesktop=20&copyRight=block'
    },
]

export default dataTeam;