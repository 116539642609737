const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'Industries',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Events & Exhibitions',
                links: '/industries/events'
            },
            {
                id: 2,
                sub: 'Experiential marketing',
                links: '/industries/marketing'
            },
            {
                id: 3,
                sub: 'Real Estate & Architects',
                links: '/industries/real-estate'
            },
            {
                id: 4,
                sub: 'Retail & Showrooms',
                links: '/industries/retail-stores'
            },
            {
                id: 5,
                sub: 'HealthCare',
                links: '/industries/healthcare'
            }, 
            {
                id: 6,
                sub: 'Education',
                links: '/industries/education'
            },
            {
                id: 7,
                sub: 'Hospitality',
                links: '/industries/hospitality'
            },
            {
                id: 9,
                sub: 'Ecommerce',
                links: '/industries/ecommerce'
            },
            
            // {
            //     id: 10,
            //     sub: 'Architecture',
            //     links: '/industries/architecture'
            // },
            {
                id: 8,
                sub: 'Art',
                links: '/industries/art'
            },

            // {
            //     id: 5,
            //     sub: 'Manufacturers',
            //     links: '/industries/manufacturers'
            // },
        ]
    },
    {
        id: 3,
        name: 'Services',
        links: '#',
        namesub: [
            {
                id: 7,
                sub: 'Technology Consultancy & workshops',
                links: '/services/technology-consultancy-and-workshops'
            },
            {
                id: 1,
                sub: 'Extended Reality (XR MR AR VR)',
                links: '/services/extended-reality'
            },
            {
                id: 8,
                sub: 'Experiential Technology & Interior',
                links: '/services/experiential-technology-interior'
            },
            {
                id: 2,
                sub: 'Event Tech & Booth Stands',
                links: '/services/event-tech-and-booth-stands'
            },
            {
                id: 3,
                sub: 'Virtual Web Development',
                links: '/services/virtual-web-development'
            },
            {
                id: 4,
                sub: 'Interactive Virtual Tours',
                links: '/services/interactive-virtual-tours'
            },
            {
                id: 5,
                sub: 'Metaverse Services',
                links: '/services/metaverse-services'
            },
            {
                id: 6,
                sub: 'Interactive Apps/Games',
                links: '/services/interactive-games'
            },
            {
                id: 7,
                sub: 'Interactive Installations',
                links: '/services/interactive-installations'
            },
        ]
    },
    {
        id: 4,
        name: 'Solutions',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'AR Visualizer',
                links: '/solutions/ar-visualizer'
            },
            {
                id: 2,
                sub: 'Virtual Designs Hub',
                links: '/solutions/virtual-designs-hub'
            },
            {
                id: 3,
                sub: 'AI Virtual Beings',
                links: '/solutions/ai-virtual-beings'
            },
            // {
            //     id: 4,
            //     sub: 'Transparent Screens',
            //     links: '/solutions/S05'
            // },
            {
                id: 5,
                sub: 'Smart Stands',
                links: '/solutions/smart-stands'
            }, 
            // {
            //     id: 6,
            //     sub: 'Virtual Try-On',
            //     links: '/solutions/S08'
            // },
            {
                id: 7,
                sub: 'Holographic Displays',
                links: '/solutions/holographic-displays'
            },
        ]
    },
    {
        id: 5,
        name: 'Company',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'About Us',
                links: '/about'
            },
            {
                id: 2,
                sub: 'Projects',
                links: '/projects'
            },
        ]
    },
    {
        id: 6,
        name: 'Contact',
        links: '/contact',
    },
    // {
    //     id: 89,
    //     name: 'Blog',
    //     links: '/blog'
    // },
    
]

export default menus;