import React from "react";
import Button from "../button/Button";
import PropTypes from "prop-types";

ArtDetails.propTypes = {
  data: PropTypes.array,
};

function ArtDetails(props) {
  const { data } = props;

  return (
    <div>
      {data.map((item, index) => {
        // Set default value for topHeadingDesign
        const topHeading = item.topHeadingDesign !== undefined ? item.topHeadingDesign : false;

        return (
          <section className={`${!topHeading ? "about" : ""}`} key={index}>
            {!data.some(item => item.img) && <div className="shape right"></div>}
            <div>
              {!topHeading && (
                <div className="container">
                  <div className="block-text center mt-12">
                    {item.title && <h2 className="heading">{item.title}</h2>}
                    {item.titleH3 && <h3 className="heading">{item.titleH3}</h3>}
                    <p className="mb-17 font-20" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    {item.button && (
                      <Button
                        title={item.btnTitle}
                        link={item.link}
                        target={item.target || ""}
                      />
                    )}
                  </div>
                </div>
              )}

              {item.img && !item.scan && (
                <div className="main-sec-with-img">
                  {topHeading && (
                    <div className="container" style={{ right: item.headingRight ? "50px" : "auto", left: item.headingRight ? "auto" : "50px" }}>
                      <div className={`block-text mt-12 ${item.headingRight ? "text-end" : ""}`}>
                        <h3 className="heading">{item.title}</h3>
                        <p className="mb-17 font-20" dangerouslySetInnerHTML={{ __html: item.desc }} />
                        {item.button && (
                          <Button
                            title={item.btnTitle}
                            link={item.link}
                            target={item.target || ""}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <img src={item.img} className="w-100" style={{ maxHeight: "initial", width: "100%" }} alt="Item" />
                </div>
              )}

              <div className="container">
                {(item.iframe || item.video || item.scan) && (
                  <div
                    className="project-box"
                    style={{
                      background: item.backTransparent ? "transparent" : "",
                      border: item.backTransparent ? "0px" : "",
                    }}
                  >
                    {item.iframe && (
                      <div style={{ position: "relative" }} className="iframe cls-70vh">
                        <iframe
                          src={item.iframe}
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                          style={{ width: "100%", height: "100%" }}
                          title={item.title}
                          className="embed-responsive-item"
                        ></iframe>
                      </div>
                    )}
                    {item.video && (
                      <div style={{ position: "relative" }} className="video cls-70vh">
                        <iframe
                          src={item.video}
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                          style={{ width: "100%", height: "100%" }}
                          title={item.title}
                          className="embed-responsive-item"
                        ></iframe>
                      </div>
                    )}
                    {item.scan && (
                      <div style={{ position: "relative" }} className="iframe cls-70vh mobile-100 text-center">
                        <img src={item.img} style={{ height: "100%" }} alt="AVRLabs" />
                      </div>
                    )}
                  </div>
                )}

                {item.iframe2 && (
                  <div className="project-box">
                    <div style={{ position: "relative" }} className="iframe cls-70vh">
                      <iframe
                        src={item.iframe2}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{ width: "100%", height: "100%" }}
                        title={item.title}
                        className="embed-responsive-item"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
}

export default ArtDetails;
